.demo__box {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  min-width: 420px;
}
fieldset legend {
  display: none !important;
}

.demo__first {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.demo__upload {
  margin-top: 15px;
}

.file__box {
  height: 70px;
  background-color: #396db062;
  border-radius: 5px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

.file__box p {
  font-weight: bold;
  color: #396db0;
}

.step__btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  margin-top: 15px;
  border-top: 1px solid rgba(177, 177, 177, 0.205);
}

.step__btns button {
  border: none;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px #396db048;
  background-color: #396db062;
  font-weight: bold;
  color: #396db0;
  font-size: 1.1rem;
  cursor: pointer;
}

.step__btns .back {
  box-shadow: 0px 5px 15px #9e9e9e48;
  background-color: #9e9e9e7a;
  font-weight: bold;
  color: #9e9e9e;
}

.demo__second,
.demo__third {
  display: grid;
  grid-template-rows: auto auto auto;
}

.demo__processing {
  display: flex;

  column-gap: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(177, 177, 177, 0.205);
}

.demo__processing p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.demo__email {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}

.demo__email .email-input {
  margin-top: 15px;
  margin-bottom: 10px;
}

.demo__third .demo__results {
  padding-top: 15px;
  display: grid;
  grid-template-columns: minmax(450px, 700px) 450px;
  /* grid-template-columns: minmax(450px, auto); */
  column-gap: 25px;
}

.demo__third .demo__results .results__table {
  max-height: 500px;
  min-height: 400px;
  overflow-y: auto;
  overflow: auto;
}

.demo__third .demo__results .results__table span {
  color: #396db0 !important;
}
.demo__third .demo__results .results__table td span {
  color: black !important;
}
/* .results__table table {
  background-color: grey;
} */
/* .results__table table td {
  text-align: left;
} */
.MuiTableContainer-root {
  height: 100%;
  overflow-y: hidden;
}

.pdfDocument {
  width: 450px;
  height: 500px;
  overflow-y: auto;
  overflow-x: visible;
}

.pdfPage {
  margin-left: 10px;
  width: 100%;
  max-width: 350px;

  overflow: auto;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px 5px #9e9e9e48;
  overflow: visible;
}

.boundingBox {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 100px;
  height: 100px;
  z-index: 5;
  background-color: #396db00c;
  border: solid 1px #396db05e;
  border-radius: 3px;
  transition: 0.2s;
}

.boundingBox:hover {
  background-color: #396db048;
  border: solid 1px #396db0;
}

.pdfZoomControls {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 5;
  background-color: white;
}
.boundingBox .boundingBoxDescription {
  position: absolute;
  opacity: 0;
  left: -20;
  top: 20px;
  transition: 0.2s;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.548);
  color: white;
  font-size: 12px;
  display: none;
}

.boundingBox:hover .boundingBoxDescription {
  display: inline;
  opacity: 1;
  left: 0;
}
