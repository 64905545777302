@media (max-width: 970px) {
  .demo__results {
    grid-template-columns: none !important;
    grid-template-rows: minmax(300px, auto) minmax(300px, 500px);
  }
  .results__table {
    max-height: max-content !important;
  }
  .pdfDocument {
    overflow-y: initial;
  }
}

@media (max-width: 700px) {
  .step__btns {
    flex-direction: column;
    align-items: baseline;
  }
  .step__btns button {
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .demo__box {
    min-width: 0px;
    width: 85%;
  }
  .pdfPage {
    width: auto;
  }
  .pdfPage canvas {
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .pdfDocument {
    width: 100%;
    height: auto;
  }
}
