@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

button,
p,
a,
span,
input {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
}

.demo__container {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
